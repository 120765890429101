import React from 'react';

export const parseMessage = text => {
	return text.split('\n').map((segment, index, array) =>
		// Only add <br> if it's not the last segment
		index === array.length - 1 ? (
			segment
		) : (
			<React.Fragment key={index}>
				{segment}
				<br />
			</React.Fragment>
		)
	);
};
