import { store } from '../../store';

export const isRtl = text => {
	const rtlCharRanges = [
		[0x0590, 0x05ff], // Hebrew
		[0x0600, 0x06ff], // Arabic
		[0x0750, 0x077f], // Arabic Supplement
		[0x08a0, 0x08ff], // Arabic Extended-A
		[0xfb50, 0xfdff], // Arabic Presentation Forms-A
		[0xfe70, 0xfeff], // Arabic Presentation Forms-B
		[0x07c0, 0x07ff], // N'Ko
		[0x0800, 0x083f], // Samaritan
		[0x0840, 0x085f], // Mandaic
		[0x0860, 0x086f], // Syriac Supplement
		[0x0700, 0x074f], // Syriac
		[0x0870, 0x089f] // Arabic Extended-B
	];

	if (!text) return store.getState().language.isRtl;

	const firstChar = text.charCodeAt(0);

	return rtlCharRanges.some(([start, end]) => firstChar >= start && firstChar <= end);
};
