export const loadingTexts = {
	he: {
		chat: [
			'אוסף מידע',
			'מכין שאילתא',
			'שולף נתונים',
			'עיבוד הנתונים',
			'בדיקת תוצאות',
			'הכנת מסקנות',
			'בניית תשובה',
			'חידוד בהירות התשובה',
			'עובר הלוגיקה שוב',
			'בודק שוב מול מקור המידע',
			'בונה תשובה מחדש',
			'נראה שזה לוקח מעט זמן, אל דאגה'
		],
		welcome: ['טוענים מודולים', 'בונים את הקסם', 'מרכיבים את הפאזל', 'מחממים מנועים']
	},
	en: {
		chat: [
			'Gathering information',
			'Crafting query',
			'Retrieving data',
			'Analyzing data',
			'Reviewing findings',
			'Drawing conclusions',
			'Constructing response',
			'Clarifying response',
			'Reviewing logic again',
			'Validating again with data source',
			'Refining response again',
			'Slight delay, still progressing'
		],
		welcome: ['Powering up the system', 'Loading the magic', 'Getting things ready', 'Spinning up the gears']
	}
};
