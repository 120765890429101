import { useState, useEffect } from 'react';

import BotMessage from './BotMessage';
import UserMessage from './UserMessage';
import Answer from './Answer';
import Messages from './Messages';
import InputArea from './InputArea';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import { isRtl } from '../../app/utils/isRtl';

function Chat({ thread }) {
	const [messages, setMessages] = useState([]);
	const [isAnswering, setIsAnswering] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setMessages([<BotMessage key="0" message={t('WELCOME_MESSAGE')} isRtl={isRtl(t('WELCOME_MESSAGE')) ? 'rtl' : ''} isLoading={false} />]);
	}, [t]);

	const send = async message => {
		//
		setIsAnswering(true);

		const newMessages = messages.concat(
			<UserMessage key={messages.length + 1} message={message} />,
			<Answer key={messages.length + 2} message={message} setIsAnswering={setIsAnswering} />
		);
		setMessages(newMessages);
	};

	return (
		<>
			<div className="chat">
				<Header />
				<Messages messages={messages} />
				<InputArea onSend={send} isAnswering={isAnswering} />
			</div>
		</>
	);
}

export default Chat;
