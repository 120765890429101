import { useDispatch } from 'react-redux';
import { setIsRtl, setLanguage } from '../store';
import { useEffect } from 'react';
import i18n from 'i18next';
import { _config } from '../app/config';
import { _langauges } from '../app/locale/i18n';

const useLangauge = () => {
	//
	const dispatch = useDispatch();

	useEffect(() => {
		const urlLang = new URLSearchParams(window.location.search).get('lang');

		const lang = urlLang && _langauges.includes(urlLang) ? urlLang : _config.defaultLanguage;

		localStorage.setItem('lang', lang);
		dispatch(setLanguage(lang));
		dispatch(setIsRtl(lang === 'he'));
		i18n.changeLanguage(lang);

		// Call server side to check
	}, [dispatch]);
};

export default useLangauge;
