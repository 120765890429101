import { createApi } from '@reduxjs/toolkit/query/react';
import { authedExtendFetchBaseQuery } from '../extend';

const chatApi = createApi({
	reducerPath: 'chat',
	baseQuery: authedExtendFetchBaseQuery(),
	endpoints(builder) {
		return {
			start: builder.query({
				query: name => {
					return {
						method: 'GET',
						url: `start`
					};
				}
			}),
			question: builder.mutation({
				query: body => {
					return {
						method: 'POST',
						url: `question`,
						body
					};
				}
			})
		};
	}
});

export const { useStartQuery, useQuestionMutation } = chatApi;
export { chatApi };
