import './scss/style.scss';
import Chat from './components/chat/Chat';
import Welcome from './components/Welcome';
import { useStartQuery } from './store';
import { useEffect, useState } from 'react';
import Error from './components/Error';
import { useSelector } from 'react-redux';

function App() {
	//

	const wialonAuth = useSelector(state => state.wialonAuth);

	const { data, error } = useStartQuery(undefined, {
		skip: !wialonAuth.logged // Avoid race condition
	});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (data || error) {
			setLoading(false);
		}
	}, [data, error]);

	return loading ? <Welcome /> : error ? <Error /> : <Chat thread={data.thread} />;
}

export default App;
