import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { wialonLoginSlice } from './slices/wialonLoginSlice';
import { languageSlice } from './slices/languageSlice';
import { appApi } from './apis/appApi';
import { chatApi } from './apis/chatApi';
import { wialonLoginApi } from './apis/wialonLoginApi';
import { styleSlice } from './slices/styleSlice';

const store = configureStore({
	reducer: {
		wialonAuth: wialonLoginSlice.reducer,
		language: languageSlice.reducer,
		style: styleSlice.reducer,
		[appApi.reducerPath]: appApi.reducer,
		[chatApi.reducerPath]: chatApi.reducer,
		[wialonLoginApi.reducerPath]: wialonLoginApi.reducer
	},
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware().concat([chatApi.middleware, appApi.middleware, wialonLoginApi.middleware]);
	}
});

setupListeners(store.dispatch);

export { store, wialonLoginSlice, languageSlice, styleSlice };
export const { setLanguage, setIsRtl } = languageSlice.actions;
export const { setIsDarkMode } = styleSlice.actions;
export const { setAccessToken, setSid, setLogged, logout } = wialonLoginSlice.actions;

export { useStartQuery, useQuestionMutation } from './apis/chatApi';
export { useConfigQuery } from './apis/appApi';
export { useLoginQuery } from './apis/wialonLoginApi';
