import { useDispatch, useSelector } from 'react-redux';
import { setIsDarkMode } from '../store';

const useStyle = () => {
	//
	const dispatch = useDispatch();
	const style = useSelector(state => state.style);

	return {
		toggleDarkModelEnabled: () => {
			//
			if (!style.isDarkMode) {
				localStorage.setItem('isDarkMode', '1');
			} else {
				localStorage.setItem('isDarkMode', '0');
			}

			dispatch(setIsDarkMode(!style.isDarkMode));
		}
	};
};

export default useStyle;
