import { useTranslation } from 'react-i18next';

const Error = () => {
	const { t } = useTranslation();

	return (
		<div className="welcome is-flex is-justify-content-center is-align-items-center">
			<article className="message is-warning">
				<div className="message-header is-justify-content-center ">
					<i className="fa-solid fa-triangle-exclamation is-size-2"></i>
					{t('LOADING_ERROR_TITLE')}
				</div>
				<div className="message-body">
					{t('LOADING_ERROR')}
					<br />
					<a href={`mailto:${t('EMAIL')}`}>{t('EMAIL')}</a>
				</div>
			</article>
		</div>
	);
};

export default Error;
