import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { store } from '../../store';

import translationEn from './en.json';
import translationHe from './he.json';

export const _langauges = ['en', 'he'];

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: translationEn
		},
		he: {
			translation: translationHe
		}
	},
	lng: store.getState().language.lang,
	fallbackLng: store.getState().language.lang,
	interpolation: {
		escapeValue: false
	}
});

export default i18n;
