import Loading from './Loading';

const Welcome = ({ src, ...rest }) => {
	return (
		<div className="welcome is-flex is-justify-content-center is-align-items-center">
			<Loading isLarge={true} />
		</div>
	);
};

export default Welcome;
