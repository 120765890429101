import { createSlice } from '@reduxjs/toolkit';
import { _config } from '../../app/config';

const styleSlice = createSlice({
	name: 'style',
	initialState: {
		isDarkMode: localStorage.getItem('isDarkMode') === '1' ? true : _config.defaultDarkMode
	},
	reducers: {
		setIsDarkMode: (state, action) => {
			state.isDarkMode = action.payload;
		}
	}
});

export { styleSlice };
