import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Layout from './components/layout/Layout';
import { Provider } from 'react-redux';
import { store } from './store';
import { AppProvider } from './context/app';
import './app/locale/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<AppProvider>
			<Layout>
				<App />
			</Layout>
		</AppProvider>
	</Provider>
);
