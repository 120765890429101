import React from 'react';
import { parseMessage } from '../../app/utils/parseMessage';
import { isRtl } from '../../app/utils/isRtl';

export default function UserMessage({ message }) {
	return (
		<div className="message-container">
			<div className={`message user-message ${isRtl(message) ? 'rtl' : ''}`}>{parseMessage(message)}</div>
		</div>
	);
}
