import React, { useState, useEffect } from 'react';
import { useQuestionMutation, useStartQuery } from '../../store';
import { useTranslation } from 'react-i18next';
import { marked } from 'marked';
import BotMessage from './BotMessage';
import { isRtl } from '../../app/utils/isRtl';

export default function Answer({ message, setIsAnswering }) {
	//
	const [isLoading, setLoading] = useState(true);
	const [rtl, setRtl] = useState(false);
	const [answer, setAnswer] = useState('');
	const [error, setError] = useState(false);
	const [question, results] = useQuestionMutation();
	const { data } = useStartQuery();
	const { t } = useTranslation();

	marked.setOptions({
		gfm: true,
		tables: true,
		sanitize: false // Note: 'sanitize' option is deprecated and will be removed in future versions. Consider using a library like DOMPurify for sanitization.
	});

	useEffect(() => {
		question({
			thread: data.thread,
			question: message
		});
	}, [question, data.thread, message]);

	useEffect(() => {
		//
		if (results.isSuccess) {
			setLoading(false);
			setIsAnswering(false);
			setAnswer(<div dangerouslySetInnerHTML={{ __html: marked.parse(results.data.answer) }} />);
			setRtl(isRtl(results.data.answer));
		}
		if (results.error) {
			setAnswer(<span dangerouslySetInnerHTML={{ __html: t('ANSWER_ERROR') }} />);
			setError(true);
			setLoading(false);
			setIsAnswering(false);
		}
		// eslint-disable-next-line
	}, [results]);

	return <BotMessage message={answer} isRtl={rtl} error={error} isLoading={isLoading} />;
}
