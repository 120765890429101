import { createSlice } from '@reduxjs/toolkit';
import { _config } from '../../app/config';

const languageSlice = createSlice({
	name: 'language',
	initialState: {
		lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : _config.defaultLanguage,
		isRtl: localStorage.getItem('isRtl') ? localStorage.getItem('isRtl') : _config.defaultIsRtl
	},
	reducers: {
		setLanguage: (state, action) => {
			state.lang = action.payload;
		},
		setIsRtl: (state, action) => {
			state.isRtl = action.payload;
		}
	}
});

export { languageSlice };
