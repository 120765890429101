import React, { useState, useEffect } from 'react';
import { loadingTexts } from '../app/locale/loading-texts';
import { useSelector } from 'react-redux';

const Loading = ({ isLarge }) => {
	//
	const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
	const [dots, setDots] = useState('');
	const [sentences, setSentences] = useState([]);
	const [sentencesLength, setSentencesLength] = useState(0);
	const language = useSelector(state => state.language);

	useEffect(() => {
		let theSentences = loadingTexts[language.lang]['chat'];

		if (isLarge) {
			theSentences = loadingTexts[language.lang]['welcome'];
		}

		setSentences(theSentences);
		setSentencesLength(theSentences.length);
		//
	}, [language, isLarge]);

	useEffect(() => {
		if (currentSentenceIndex < sentencesLength - 1) {
			const intervalId = setInterval(() => {
				setCurrentSentenceIndex(prevIndex => prevIndex + 1);
				setDots(''); // Reset dots when sentence changes
			}, 6500); // Change sentence every 5 seconds

			return () => clearInterval(intervalId);
		}
	}, [currentSentenceIndex, sentencesLength]);

	useEffect(() => {
		setDots(''); // Reset dots when sentence changes

		const dotsInterval = setInterval(() => {
			setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : ''));
		}, 800);

		return () => clearInterval(dotsInterval);
	}, [currentSentenceIndex]);

	return (
		<div className={`loading-container ${isLarge ? 'loading-large' : ''}`}>
			<div className="loading">
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div className={`sentence-animation`}>
				<div key={sentences[currentSentenceIndex]} className="sentence">
					{sentences[currentSentenceIndex]}
					<span className="dots" data-dots={dots}></span>
				</div>
			</div>
		</div>
	);
};

export default Loading;
