import React, { useEffect, useRef, useState } from 'react';
import { isRtl } from '../../app/utils/isRtl';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function InputArea({ onSend, isAnswering }) {
	//
	const [text, setText] = useState('');
	const textareaRef = useRef(null);
	const { t } = useTranslation();
	const language = useSelector(state => state.language);

	const [rtl, setRtl] = useState();

	const handleInputChange = e => {
		setText(e.target.value);
		setRtl(isRtl(e.target.value));
	};

	const handleSend = e => {
		e.preventDefault();

		if (isAnswering || text === '') {
			return;
		}

		onSend(text);
		setText('');
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault(); // Prevent default to stop the newline
			handleSend(e); // Submit the form
		}
		// If Shift + Enter is pressed, it will just add a new line as default
	};

	useEffect(() => {
		setRtl(language.isRtl);
	}, [language.isRtl]);

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = '60px'; // Reset height to allow shrinking
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
		}
	}, [text]);

	return (
		<div className={`input-area ${rtl ? 'rtl' : ''}`}>
			<form onSubmit={handleSend}>
				<textarea ref={textareaRef} onKeyDown={handleKeyDown} onChange={handleInputChange} value={text} placeholder={t('MESSAGE_PLACEHOLDER')}></textarea>
				<button className={isAnswering ? 'disabled' : ''}>
					<i className="fa-solid fa-circle-arrow-up"></i>
				</button>
			</form>
		</div>
	);
}
