import React from 'react';
import Image from '../Image';
import Loading from '../Loading';
import { useSelector } from 'react-redux';

export default function BotMessage({ message, error, isLoading, isRtl }) {
	//
	const style = useSelector(state => state.style);

	return (
		<div className="message-container">
			<div className="bot-wrapper">
				<Image className="tracer-icon" src={`${style.isDarkMode ? 'icon-w.png' : 'icon.png'}`} />
				<div className={`message bot-message ${isRtl ? 'rtl' : ''} ${error ? 'error' : ''}`}>{isLoading ? <Loading /> : message}</div>
			</div>
		</div>
	);
}
