import useModalContext from '../../hooks/useModalContext';
import Image from '../Image';
import Modal from './Modal';
import useWialonLogin from '../../hooks/useWialonLogin';
import { useSelector } from 'react-redux';
import useLangauge from '../../hooks/useLangauge';
import { useEffect } from 'react';
import useStyle from '../../hooks/useStyle';

const Layout = ({ children }) => {
	//
	useLangauge();
	const { modal } = useModalContext();
	const { logout } = useWialonLogin();
	const wialonAuth = useSelector(state => state.wialonAuth);
	const language = useSelector(state => state.language);
	const style = useSelector(state => state.style);
	const { toggleDarkModelEnabled } = useStyle();

	useEffect(() => {
		if (style.isDarkMode) {
			document.body.classList.add('dark-mode');
		} else {
			document.body.classList.remove('dark-mode');
		}
	}, [style.isDarkMode]);

	return (
		<>
			{modal && <Modal />}
			<div className={`columns is-desktop is-centered ${language.isRtl ? 'rtl' : ''}`}>
				<div className="column is-half">
					<header>
						<div className="container">
							<nav className="level is-mobile">
								<div className="level-left">
									<div className="level-item">
										<Image src={`${style.isDarkMode ? 'logo-w.png' : 'logo.png'}`} />
									</div>
								</div>
								<div className="level-right">
									<div className="level-item">
										<button className="button is-link is-light" onClick={() => toggleDarkModelEnabled()}>
											<i className="fa-solid fa-circle-half-stroke"></i>
										</button>
									</div>
									<div className="level-item">
										<button className="button is-danger is-light" onClick={logout}>
											<i className="fa-solid fa-right-from-bracket"></i>
										</button>
									</div>
								</div>
							</nav>
						</div>
					</header>

					<div className="container main">{wialonAuth.logged && children}</div>
				</div>
			</div>
		</>
	);
};

export default Layout;
