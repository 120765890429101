import { createSlice } from '@reduxjs/toolkit';

const wialonLoginSlice = createSlice({
	name: 'wialonAuth',
	initialState: {
		access_token: localStorage.getItem('access_token') || null,
		sid: localStorage.getItem('sid') || null,
		logged: !!Number(localStorage.getItem('logged')) || false
	},
	reducers: {
		setAccessToken: (state, action) => {
			state.access_token = action.payload;
		},
		setSid: (state, action) => {
			state.sid = action.payload;
		},
		setLogged: (state, action) => {
			state.logged = action.payload;
		},
		logout: (state, action) => {
			state.access_token = null;
			state.sid = null;
		}
	}
});

export { wialonLoginSlice };
